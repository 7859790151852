import { getLocalStorage, theme } from '@geneo2-web/shared-ui';
import { useMediaQuery } from '@mui/material';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

// Define the shape of your context data
interface IContextData {
  selectedFunction: (() => void) | null;
  setSelectedFunction: (func: (() => void) | null) => void;
  isIpadOnly: boolean;
  showAppLoader: boolean;
  setShowAppLoader: (_val: boolean) => void;
  // isAuthenticated2: string;
}

// Create the context
const globalContext = createContext<IContextData | undefined>(undefined);

const { Provider } = globalContext;

// Create a provider component
interface IProviderProps {
  children: ReactNode;
}

export const GlobalContextProvider: React.FC<IProviderProps> = ({
  children,
}) => {
  const [selectedFunction, setSelectedFunction] = useState<(() => void) | null>(
    null
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;

  const [showAppLoader, setShowAppLoader] = useState<boolean>(false);

  // const [isAuthenticated2, setIsAuthenticated2] = useState('false'); //reduntant
  useEffect(() => {
    const auth = getLocalStorage('auth');
    // console.log('isauth', auth);
    // setIsAuthenticated2(auth);
  });

  useEffect(() => {
    const handlePopState = () => {
      if (selectedFunction) {
        selectedFunction();
        setSelectedFunction(null);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [selectedFunction]);

  const contextValue: IContextData = {
    selectedFunction,
    setSelectedFunction,
    isIpadOnly,
    showAppLoader,
    setShowAppLoader,
    // isAuthenticated2,
  };
  return <Provider value={contextValue}>{children}</Provider>;
};

export const useGlobalContext = () => {
  const context = useContext(globalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a MyContextProvider');
  }
  return context;
};
