import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  ClassAndSubjectPopup,
  IClassAndSubjectSelected,
  IStyles,
  ImageWrapper,
  SecondaryOutlinedButton,
  deserify,
  firstLetterImage,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalContext } from '../../app/Context/GlobalContextProvider';
import { interactionEvent } from '../../pages/Auth/auth.events';
import {
  aiBookCloseEvent,
  aiBookOpenEvent,
} from '../../pages/Home/home.events';
import { setClassAndSubjectInfo } from '../../pages/Home/reducer/homeDashboard.slice';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import {
  HOME,
  HOMEWORK_CONGRATULATIONS,
} from '../../routeHandling/RoutesNomenclature';
import { subjectsWithClass } from '../../utils/icons';
import { useSideBarOptions } from '../../utils/sidebarOptions';

const styles: IStyles = {
  root: {
    width: '100vw',
    height: { xs: pxToRem(60), md: pxTovW(100) },
    background: 'grey',
    position: 'fixed',

    borderBottomWidth: pxTovW(1),
    borderBottomStyle: 'solid',
    borderBottomColor: 'neutral.grey',
    boxSizing: 'border-box',

    display: 'flex',
    justifyContent: { md: 'space-between' },
    alignItems: 'center',
    // gap: { xs: '30%', md: 'unset' },
    p: { xs: `0 ${pxToRem(20)}`, lg: `0 ${pxTovW(240)}` },
    bgcolor: 'common.white',
    zIndex: '1',
  },

  leftArrow: {
    cursor: 'pointer',
    width: { xs: pxToRem(14), md: pxTovW(19) },
    height: { xs: pxToRem(13), md: pxTovW(26) },
  },
  logo: {
    // display: 'flex',
    cursor: 'pointer',
    width: { xs: pxToRem(75), md: pxTovW(100) },
    height: { xs: pxToRem(42), md: pxTovW(56) },
  },

  userBox: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    gap: pxTovW(10),
    cursor: 'pointer',
  },
  userImage: {
    width: pxTovW(55),
    height: pxTovW(55),
    borderRadius: '50%',
    cursor: 'pointer',
  },

  menu: {
    mt: pxTovW(20),
    '& .MuiMenu-list': {
      width: { md: pxTovW(450), lg: pxTovW(352) },
      p: { md: `${pxTovW(12)}`, lg: `${pxTovW(12)} ${pxTovW(10)}` },
      borderRadius: pxTovW(10),
      boxShadow: `0 0 ${pxTovW(10)} #00000038`,
    },
    '& .MuiMenu-paper': {
      borderRadius: pxTovW(10),
    },
  },
  menuItem: {
    borderBottom: '1px solid #D2D2D2',
    p: { md: `${pxTovW(20)} ${pxTovW(15)}` },
    '&:last-child': {
      borderBottom: '1px solid transparent',
    },
    '&:hover': {
      backgroundColor: '#F2F2F2',
      borderBottom: '1px solid transparent',
      borderRadius: pxTovW(10),
    },
  },
  downArrow: {
    cursor: 'pointer',
    width: pxTovW(13),
    height: pxTovW(7),
  },

  menuTriple: {
    cursor: 'pointer',
    // width: pxToRem(16),
    // height: pxToRem(14),

    width: { xs: pxToRem(14), md: pxTovW(25) },
    height: { xs: pxToRem(14), md: pxTovW(18) },
  },
};

interface IProps {
  sidebarDisplay: boolean;
  setSidebarDisplay: (arg0: boolean) => void;
}

export default function Header(props: IProps) {
  const { setSidebarDisplay, sidebarDisplay } = props;

  const options = useSideBarOptions();

  const navigate = useNavigate();
  const currentPage = window.location.pathname;
  const { user_info } = deserify(useAppSelector((state) => state.auth));

  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lessonSessionId =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;
  const { selected_resource_session_id } = deserify(
    useAppSelector((state) => state.teach)
  );

  //^ SubjectPopup
  const [modalState, setModalState] = useState(false);
  //use teacher_profile_id to fill the subjects
  const { selectedFunction, setSelectedFunction, isIpadOnly } =
    useGlobalContext();
  const goBack = async () => {
    console.log('go back');

    if (selectedFunction) {
      selectedFunction();
      setSelectedFunction(null);
      // console.log('selected function');
      await interactionEvent({
        url: '',
        context: 'left_arrow',
        name: 'BACK',
        pathSegments: pathSegments,
        lessonSessionId: lessonSessionId,
        resourceSessionId: selected_resource_session_id?.toString(),
      });
      return;
    } else if (options.some((elem) => elem.route === currentPage)) {
      console.log('options');
      navigate(HOME);
    } else {
      console.log('reset');
      navigate(-1);
    }
    await interactionEvent({
      url: '',
      context: 'left_arrow',
      name: 'BACK',
      pathSegments: pathSegments,
      lessonSessionId: lessonSessionId,
      resourceSessionId: selected_resource_session_id?.toString(),
    });
  };

  const { chapter_resources, selected_topic_info, selected_lessons_info } =
    deserify(useAppSelector((state) => state.teach));
  const { selected_hw_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );

  const closeAiFunctions = async () => {
    // await aiLessonCloseEvent(selected_lessons_info?.lessonId);
    // await aiTopicCloseEvent(selected_topic_info?.topicId);
    // await aiChapterCloseEvent(chapter_resources?.chapterId);

    // await aiHomeworkCloseEvent(selected_hw_id);

    await interactionEvent({
      url: '',
      context: 'navigation_bar',
      name: 'GENEO',
      pathSegments: pathSegments,
      lessonSessionId: lessonSessionId,
      resourceSessionId: selected_resource_session_id?.toString(),
    });
  };
  const logoClickHandler = () => {
    // console.log('logoClickHandler:');
    closeAiFunctions();
    navigate(HOME);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    await interactionEvent({
      url: '',
      context: 'Navigation_bar',
      name: 'MENU',
      pathSegments: pathSegments,
      lessonSessionId: lessonSessionId,
      resourceSessionId: selected_resource_session_id?.toString(),
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //
  const classAndsubjectClickHandler = async (
    inputClassInfo: IClassAndSubjectSelected
  ) => {
    await interactionEvent({
      url: '',
      context: 'Subject_&_Class_Selection',
      name: inputClassInfo.subject.toUpperCase(),
      pathSegments: pathSegments,
      lessonSessionId: lessonSessionId,
      resourceSessionId: selected_resource_session_id?.toString(),
    });
    if (class_subject_info) aiBookCloseEvent(class_subject_info.bookId);
    await aiBookOpenEvent(inputClassInfo.bookId, uuidv4());
    await dispatch(setClassAndSubjectInfo(inputClassInfo));
    await setModalState(false);
  };

  return (
    <Box sx={styles.root}>
      {/* {currentPage !== "/" && } */}

      {currentPage !== '/home' && currentPage !== HOMEWORK_CONGRATULATIONS ? (
        <Box onClick={goBack}>
          <KeyboardBackspaceIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
        </Box>
      ) : (
        <Box sx={{ display: 'none' }}></Box>
      )}

      <Box
        // onClick={logoClickHandler}
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(14), md: pxTovW(25) },
          margin: { xs: 'auto', md: 'unset' },
        }}
      >
        <ImageWrapper
          name="geneo-logo"
          type="png"
          parentFolder="images"
          styles={styles.logo}
          onClick={logoClickHandler}
        />

        {currentPage === '/home' && !isMobile ? (
          <Box>
            <SecondaryOutlinedButton
              size={isIpadOnly ? 'medium' : 'small'}
              witharrow={class_subject_info ? false : true}
              onClick={async () => {
                await interactionEvent({
                  url: '',
                  context: 'Subject_&_Class_Selection',
                  name: 'CHOOSE_SUBJECT',
                  pathSegments: pathSegments,
                  lessonSessionId: lessonSessionId,
                  resourceSessionId: selected_resource_session_id?.toString(),
                });

                setModalState(true);
              }} //dispatch action setSchoolCourseId in homeDashboard slice
            >
              {class_subject_info && class_subject_info?.classname ? (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">{`${class_subject_info?.classname} ${class_subject_info?.section} - ${class_subject_info?.subject}`}</Typography>
                  <CloseIcon
                    sx={{
                      color: 'common.black',
                      fontSize: {
                        xs: pxToRem(14),
                        md: pxToRem(14),
                        lg: pxTovW(18),
                      },
                    }}
                    onClick={async (ev) => {
                      ev.stopPropagation();
                      await aiBookCloseEvent(class_subject_info?.bookId);
                      dispatch(setClassAndSubjectInfo(undefined));
                    }}
                  />
                </Box>
              ) : (
                <Typography variant="h4">Choose Class & Subject</Typography>
              )}
            </SecondaryOutlinedButton>
          </Box>
        ) : (
          <Box sx={{ display: 'none' }}></Box>
        )}
      </Box>

      <Box sx={styles.userBox}>
        {/* Fill details with teacher_profile_id */}
        <ImageWrapper
          name="userBlue"
          path={
            user_info?.profilePics[0]?.url ||
            firstLetterImage(user_info?.firstName)
          }
          type="png"
          parentFolder="icons"
          styles={styles.userImage}
        />

        <Typography variant="bodyText" fontWeight="medium">
          {`${user_info?.firstName} ${user_info?.lastName}`}
        </Typography>

        <Box onClick={(e) => handleClick(e)} pt={pxTovW(10)}>
          <KeyboardArrowDownIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={styles.menu}
        >
          {options.map((opt, index) => (
            <MenuItem
              key={opt.name}
              onClick={() => {
                handleClose();
                opt.onClick();
              }}
              sx={styles.menuItem}
            >
              <ImageWrapper
                name={opt.icon}
                parentFolder="icons"
                type="png"
                styles={{
                  width: { xs: pxTovW(21), md: pxToRem(12), lg: pxTovW(21) },
                  marginRight: pxTovW(15),
                }}
              />

              <Typography variant="h3" color="neutral.royalBlue">
                {opt.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box
        onClick={async (e) => {
          await interactionEvent({
            url: '',
            context: 'Navigation_bar',
            name: 'MENU',
            pathSegments: pathSegments,
            lessonSessionId: lessonSessionId,
            resourceSessionId: selected_resource_session_id?.toString(),
          });
          setSidebarDisplay(!sidebarDisplay);
        }}
        sx={{ display: { xs: 'block', md: 'none', zIndex: 'toolTip' } }}
      >
        <ImageWrapper
          name="menuTriple"
          type="png"
          parentFolder="icons"
          styles={styles.menuTriple}
        />
      </Box>

      <ClassAndSubjectPopup
        modalState={modalState}
        setModalState={setModalState}
        displayData={subjectsWithClass}
        classAndsubjectClickHandler={classAndsubjectClickHandler}
        classSubjectsList={user_info?.teachClassSubjects}
      />
    </Box>
  );
}
