import {
  IStyles,
  InputField,
  Loader,
  PrimaryButton,
  addOfflineUserRequest,
  callTeacherEventsApi,
  clearLocalStorageKeys,
  deserify,
  generatePasswordHash,
  getEnvConfig,
  getLocalStorage,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  PasswordTypeEnum,
  TeacherLoginResponseType,
} from '@protos/user_management/ums.login.apis_pb';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { LOGIN } from '../../../routeHandling/RoutesNomenclature';
import { resetAnalyticsState } from '../../Analytics/reducer/analytics.slice';
import { aiLogoutEvent, interactionEvent } from '../../Auth/auth.events';
import {
  resetAuthState,
  setToastInfo as setAuthToastInfo,
} from '../../Auth/reducer/auth.slice';
import { aiBookCloseEvent } from '../../Home/home.events';
import {
  resetHomeDashboardState,
  setToastInfo,
} from '../../Home/reducer/homeDashboard.slice';
import { aiHomeworkCloseEvent } from '../../Homework/homework.events';
import { resetHomeworkState } from '../../Homework/reducer/homework.slice';
import { resetManageHWState } from '../../ManageHomework/reducer/manageHomework.slice';
import {
  resetTeachState,
  setSelectedResourceSessionId,
} from '../../Teach/reducer/teach.slice';
import {
  aiChapterCloseEvent,
  aiLessonCloseEvent,
  aiTopicCloseEvent,
} from '../../Teach/teach.events';
import { useGlobalContext } from 'apps/teacher/src/app/Context/GlobalContextProvider';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
const styles: IStyles = {
  root: {
    // height: '95vh',
    display: 'flex',
    paddingX: { xs: pxToRem(20), lg: 0 },
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(40) },
    justifyContent: { xs: 'start', md: 'center' },
    alignSelf: 'center',
  },
  heading: {
    paddingTop: { xs: pxToRem(32), md: pxTovW(37) },
    textAlign: { xs: 'start', md: 'center' },
  },
};
export const ChangePassword = () => {
  const navigate = useNavigate();
  const { isOffline } = useDownloadContext();
  const dispatch = useDispatch();
  const { isIpadOnly } = useGlobalContext();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const isMCBUser = deserify(useAppSelector((state) => state.auth.is_mcb_user));
  const config = getEnvConfig();
  const { user_validation_info, user_info, ssoRedirectionUrl } = deserify(
    useAppSelector((state) => state.auth)
  );
  // const profileId = getLocalStorage('userId');
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { chapter_resources, selected_topic_info, selected_lessons_info } =
    deserify(useAppSelector((state) => state.teach));
  const { selected_hw_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const commonServiceClientContext = useCommonServiceClientContext();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [currentPassword, setCurrentPassword] = useState('');
  const [latestPassword, setLatestPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [currentShowPassword, setCurrentShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowcurrentPassword = () =>
    setCurrentShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const closeAiFunctions = async () => {
    await aiLessonCloseEvent(selected_lessons_info?.lessonId);
    await aiTopicCloseEvent(selected_topic_info?.topicId);
    await aiChapterCloseEvent(chapter_resources?.chapterId);
    await aiBookCloseEvent(class_subject_info?.bookId);
    await aiHomeworkCloseEvent(selected_hw_id);
    await aiLogoutEvent();
    dispatch(setSelectedResourceSessionId(undefined));
    // a call to clear backlog of events stored in indexDb

    !isOffline && (await callTeacherEventsApi(commonServiceClientContext));
  };

  const confirmClickHandler = async () => {
    if (!currentPassword) {
      dispatch(
        setToastInfo({
          label: 'Current password not entered',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (!latestPassword) {
      dispatch(
        setToastInfo({
          label: 'New password not entered ',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (confirmPassword !== latestPassword) {
      dispatch(
        setToastInfo({
          label: 'Please confirm the right password',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    try {
      setLoading('loading');
      const PasswordUpdation =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updatePassword({
          profileId: user_info?.teacherProfileId,
          oldPassword: currentPassword,
          newPassword: latestPassword,
          role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          passwordType: PasswordTypeEnum.PASSWORD_TYPE_UPDATE,
        });
      if (PasswordUpdation.status === 200) {
        if (user_info) {
          const passwordHash = generatePasswordHash(latestPassword);
          await addOfflineUserRequest(
            new TeacherLoginResponseType(user_info),
            passwordHash
          );
        }
        closeAiFunctions();
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
            profileId: user_info?.teacherProfileId,
            role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          });
        let redirectUrl = undefined;
        if (isMCBUser && ssoRedirectionUrl) {
          redirectUrl = ssoRedirectionUrl;
        }
        dispatch(resetAuthState());
        dispatch(resetHomeDashboardState());
        dispatch(resetHomeworkState());
        dispatch(resetManageHWState());
        dispatch(resetTeachState());
        dispatch(resetAnalyticsState());
        // sessionStorage.removeItem('fcmToken');
        clearLocalStorageKeys();
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          navigate(LOGIN);
        }
        setLoading('completed');
        dispatch(
          setAuthToastInfo({
            label: 'Password Updated Successfully',
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: '',
          context: 'Phone_number',
          name: 'ADD',
          pathSegments: pathSegments,
        });
      } else {
        dispatch(
          setToastInfo({
            label: `Password update Failed: ${PasswordUpdation.message
              .split(' ')
              .slice(1)
              .join(' ')}`,
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (err: any) {
      console.log('message', err.message.split(' ').slice(1).join(' '));
      setLoading('error');
      dispatch(
        setToastInfo({
          label: `Password update Failed: ${err.message
            .split(' ')
            .slice(1)
            .join(' ')}`,
          variant: 'error',
          open: true,
        })
      );
      console.log(err);
    }
  };

  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1" paddingTop={1}>
          Change Your
        </Typography>
        <Typography
          variant="h1"
          fontWeight={'bold'}
          color={'primary.main'}
          paddingTop={2}
        >
          Password
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '50%',
            lg: pxTovW(774),
          },
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: pxToRem(20), md: pxTovW(30) },
          alignSelf: 'center',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: pxTovW(774),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography
            variant={isIpadOnly ? 'h3' : 'cardText'}
            fontWeight="bold"
          >
            Current Password
          </Typography>
          <InputField
            value={currentPassword}
            onChange={(e) => {
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setCurrentPassword(e.target.value);
              }
            }}
            variant="outlined"
            fullWidth
            boldtext
            nonCircular
            type={currentShowPassword ? 'text' : 'password'}
            sx={{
              // backgroundColor: 'red',
              alignItems: 'center',
              gap: pxTovW(10),
              '& > div': { backgroundColor: '#F5FAFF' },
              '& .MuiFormHelperText-root': {
                width: '100%',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowcurrentPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {currentShowPassword ? (
                      <Visibility
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: pxTovW(774),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography
            variant={isIpadOnly ? 'h3' : 'cardText'}
            fontWeight="bold"
          >
            New Password
          </Typography>
          <InputField
            value={latestPassword}
            onChange={(e) => {
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setLatestPassword(e.target.value);
              }
            }}
            variant="outlined"
            fullWidth
            boldtext
            nonCircular
            type={showPassword ? 'text' : 'password'}
            sx={{
              // backgroundColor: 'red',
              alignItems: 'center',
              gap: pxTovW(10),
              '& > div': { backgroundColor: '#F5FAFF' },
              '& .MuiFormHelperText-root': {
                width: '100%',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        sx={{
                          margin: pxToRem(13),
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '100%',
              lg: pxTovW(774),
            },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography
            variant={isIpadOnly ? 'h3' : 'cardText'}
            fontWeight="bold"
          >
            Confirm Password
          </Typography>
          <InputField
            value={confirmPassword}
            // onKeyDown={confirmClickHandler}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) => {
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setConfirmPassword(e.target.value);
              }
            }}
            variant="outlined"
            fullWidth
            boldtext
            nonCircular
            type="password"
            sx={{
              // backgroundColor: 'red',
              alignItems: 'center',
              gap: pxTovW(10),
              '& > div': { backgroundColor: '#F5FAFF' },
              '& .MuiFormHelperText-root': {
                width: '100%',
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '40%',
            lg: pxTovW(520),
          },
          display: 'flex',
          alignSelf: 'center',
          marginTop: { xs: pxToRem(10), md: pxTovW(13) },
        }}
      >
        <PrimaryButton
          // ref={passwordRef}
          fullWidth
          onClick={confirmClickHandler}
          // disabled={latestPassword === '' || confirmPassword === ''}
        >
          <Typography
            variant={isIpadOnly ? 'h1' : 'cardText'}
            sx={{ color: 'common.white' }}
          >
            Confirm Password
          </Typography>
        </PrimaryButton>
      </Box>
    </Box>
  );
};
