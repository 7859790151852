import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';

import { Box, Typography, useMediaQuery } from '@mui/material';

import {
  ChipBadge,
  ContentDetailCard,
  ContentLockPopup,
  IStyles,
  ImageWrapper,
  InfoPopup,
  LessonPlanInfoPanel,
  LockToggleButton,
  PrimaryButton,
  SectionListWithTopCarousel,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  firstLetterImage,
  getEnumKeyByEnumValue,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  getTeacherSubjectEnum,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';

import { Timestamp } from '@bufbuild/protobuf';
import { NoContentCard } from '@geneo2-web/shared-ui';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import {
  ChapterMetaInfo,
  DownloadedSubject,
  Module_ModuleCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  SessionModeEnum,
  SessionStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
  LessonNodeLock,
} from '@protos/school_management/school.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { DownloadButtonWrapper } from 'apps/teacher/src/components/DownloadButtonWrapper';
import { v4 as uuidv4 } from 'uuid';
import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import {
  TEACHING_FLOW,
  TEACH_TOPIC_SELECTION,
} from '../../../routeHandling/RoutesNomenclature';
import { onResourceClick } from '../../../utils/resource';
import { getResourceType, interactionEvent } from '../../Auth/auth.events';
import { aiBookOpenEvent } from '../../Home/home.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { ReadymadeHomeworkSection } from '../../Homework/CreateHomework/components/ReadymadeHw';
import { setBaseStationDetails } from '../reducer/connectedClass.slice';
import {
  setLessonPlanRedirectionPath,
  setLessonsByModule,
  setSelectedLessonInfo,
  setSessionMode,
  setTopicResources,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../reducer/teach.slice';
import {
  aiChapterOpenEvent,
  aiTopicCloseEvent,
  aiTopicOpenEvent,
} from '../teach.events';
import { ReadylessonShimmer, ResourceShimmer } from './shimmer';

const styles: IStyles = {
  root: {
    padding: {
      md: `${pxToRem(20)} ${pxToRem(20)}`,
      lg: `${pxTovW(40)} ${pxTovW(240)}`,
    },
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: pxToRem(20), lg: pxTovW(50) },
  },
  leftPanel: {
    // backgroundColor: 'blue',
    flexGrow: { md: 1, lg: 'inherit' },
    flexBasis: { md: 1, lg: pxTovW(424) },
  },
  rightPanel: {
    // backgroundColor: 'red',
    // flexGrow: 1,
    flexBasis: { lg: pxTovW(670) },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingLeft: { xs: pxToRem(20), md: 0 },
    paddingTop: { xs: pxToRem(20), md: 0 },
  },
  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    padding: { xs: pxToRem(20), md: 0 },
  },
  cardsContainer: {
    paddingTop: pxTovW(20),
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(25), md: pxTovW(20) },
  },

  twoLineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    width: '90%',
  },
};

interface FetchFuncArg {
  subjectId: string;
  chapterId: string;
  topicId: string;
}

interface IlockedPopupData {
  resourceId?: string;
  lessonId?: string;
  image: string;
  noofTopics?: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
  lockedStatus: ContentLockStatusType;
}

export default function LessonPlanLists() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const [lpLoading, setLpLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [selectedLP, setSelectedLP] = useState<LessonInfo | undefined>();
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [completedResourceId, setCompletedResourceId] = useState<string[]>([]);

  const [resourceLoading, setResourceLoading] = useState<
    'loading' | 'completed' | 'error'
  >('completed');
  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);
  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLessonAPIFail, setIsLessonAPIFail] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const teacher_id = getLocalStorage('userId');
  const { subject_id, chapter_id, topic_id } = useParams();
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const location = useLocation();

  const { selected_topic_info, chapter_resources } = deserify(
    useAppSelector((state) => state.teach)
  );
  const [contentLockData, setContentLockData] =
    useState<ContentLockModuleData>();
  const [lockStatusData, setLockstatusData] = useState<LessonNodeLock[]>();
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { downloadResolution } = deserify(
    useAppSelector((state) => state.offline)
  );
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const { getBaseStationConnectionStatus, authenticateAnonymously } =
    useConnectedClassContext();
  const { setSelectedFunction } = useGlobalContext();
  const { addDownload, isDownloading } = useDownloadContext();

  const backButtonClick = async () => {
    await aiTopicCloseEvent(Number(topic_id));
    navigate(`${TEACH_TOPIC_SELECTION}/${subject_id}/${chapter_id}`);
  };

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    dispatch(setBaseStationDetails(undefined));
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const lessonPlanCardClickHandler = async (
    lessonInfo: LessonInfo | undefined,
    pathname: string,
    sessionMode: SessionModeEnum
  ) => {
    if (
      lessonInfo &&
      class_subject_info?.classId &&
      class_subject_info.sectionId
    ) {
      dispatch(setSessionMode(sessionMode));
      if (sessionMode === SessionModeEnum.SESSION_MODE_TEACH) {
        const stationDetails = await getBaseStationConnectionStatus({
          teacherId: teacher_id,
          classId: class_subject_info?.classId,
          sectionId: class_subject_info.sectionId,
        });
        if (stationDetails) {
          //deciding the connected classroom flow
          dispatch(setBaseStationDetails(stationDetails));
          authenticateAnonymously();
        }
      }
      dispatch(setSelectedLessonInfo(lessonInfo));
      dispatch(setLessonPlanRedirectionPath(pathname));
      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
          {
            teacherId: BigInt(teacher_id),
            lessonId: lessonInfo.lessonId,
          }
        );

      // console.log('lessonSessionInfo: response', response);
      setCompletedResourceId(response.data?.completedResourceIds || []);
      const newSessionInfo =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.createTeacherLessonSession(
          {
            teacherId: BigInt(teacher_id),
            lessonId: lessonInfo.lessonId,
            schoolId: user_info?.schoolDetails[0]?.schoolId,
            academicYear: 0,
            classId: class_subject_info?.classId,
            section: class_subject_info.section,
            subject:
              subject_id && !isNaN(Number(subject_id))
                ? getTeacherSubjectEnum(
                  Number(subject_id),
                  user_info?.teachClassSubjects
                )
                : undefined,
            // teacherLessonSessionId: ,
            sessionResourceIds: completedResourceId || [],
            startTime: Timestamp.fromDate(new Date()),
            sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            sessionMode: sessionMode,
          }
        );
      if (newSessionInfo.teacherLessonSessionId) {
        dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
      }
      await interactionEvent({
        url: 'Teacher_lesson_plan_lists',
        context: 'Ready_lesson_plan',
        name: 'LESSON_PLAN_OPEN',
      });
      navigate({
        pathname: `${TEACHING_FLOW}/${subject_id}/${chapter_id}/${topic_id}/${lessonInfo.lessonId}`,
        search: `?${createSearchParams({
          lessonSessionId: newSessionInfo.teacherLessonSessionId.toString(),
        })}`,
      });
    } else {
      dispatch(
        setToastInfo({
          label: 'No Lesson Plan or Class Selected',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  // Getting all the Lesson PLans by Module
  const lessonPlans = deserify(
    useAppSelector((state) => state.teach.lessons_by_module)
  );
  // const lockedStatusObject = deserify(
  //   useAppSelector((state) => state.teach.content_lock)
  // );

  async function fetchLessonsByModule(topicId: string) {
    try {
      setLpLoading('loading');

      const response =
        await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonsByModule(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            // subjectId: Number(subjectId),
            // chapterId: Number(chapterId),
            moduleId: Number(topicId),
            sectionId: class_subject_info?.sectionId,
          }
        );
      if (response) {
        // if (isContentLockFeature && teacher_id) {
        // await getLessonLockedStatus(teacher_id);
        // }
        setLpLoading('completed');

        if (response?.data) {
          // sort here
          const sortedData = response.data?.lessons.sort((a, b) =>
            a.lastSessionTime && b.lastSessionTime
              ? Number(b.lastSessionTime?.seconds) -
              Number(a.lastSessionTime?.seconds)
              : Number(b.modifiedOn?.seconds) - Number(a.modifiedOn?.seconds)
          );

          response.data.lessons = sortedData;
          dispatch(setLessonsByModule(response.data));
          const lessonPlanIds = sortedData?.map((lesson) => lesson.lessonId);
          if (isContentLockFeature && teacher_id) {
            await FetchLessonPlanlockstatus(teacher_id, lessonPlanIds);
            await getLessonLockedStatus(teacher_id);
          }
          await aiBookOpenEvent(class_subject_info?.bookId, uuidv4());
          await aiChapterOpenEvent(chapter_resources?.chapterId, uuidv4());
          await aiTopicOpenEvent(Number(topic_id));
        }
      }

      // setLoading(false);
    } catch (err) {
      setLpLoading('error');
      setIsLessonAPIFail(true);
      console.log(err);
    }
  }

  const handleOpenPopup = (
    ev?: React.MouseEvent,
    // isLocked?: number,
    // filteredVal?: LessonNodeLock,
    lesson?: LessonInfo,
    categories?: ResourceInfo
  ) => {
    ev?.stopPropagation();
    if (lesson && categories) {
      console.log(
        'Both lesson and categories are provided. Please provide only one.'
      );
      return;
    }
    if (lesson) {
      setLockedPopData({
        image: lesson?.posterImageUrl
          ? getMediaBasePath(lesson?.posterImageUrl, 'processedMediaBucket')
          : '',
        noofTopics: lesson?.resourceIds
          ? lesson?.resourceIds?.length.toString()
          : '',
        title: lesson?.title || '',
        moduleId: Number(topic_id),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        lessonId: lesson?.lessonId || '',
        lockedStatus:
          getLessonContentLockInfo(lesson.lessonId, contentLockData)
            ?.lockStatus ||
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
      });
    } else if (categories) {
      setLockedPopData({
        image: categories?.posterImageUrl
          ? getMediaBasePath(categories?.posterImageUrl, 'processedMediaBucket')
          : '',
        title: categories?.title || '',
        moduleId: Number(topic_id),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        resourceId: categories?.resourceId || '',
        lockedStatus: getCategoryLockedStatus(
          categories.resourceId,
          contentLockData
        ),
      });
    }

    // setSelectedResourceId(resourceId);
    setIsContentLockPopupOpen(true);

    console.log('nbjfhdkhjk', lockedPopupData);
  };
  const handleClosePopup = () => {
    setIsContentLockPopupOpen(false);
    setLockedPopData(null);
  };

  // const handleClosePopup = () => {
  //   setIsPopupOpen(false);
  // };

  // Getting all the Topic/Teaching Resources
  const topicResources = deserify(
    useAppSelector((state) => state.teach.topic_resources)
  );

  async function fetchTopicResources({
    subjectId,
    chapterId,
    topicId,
  }: FetchFuncArg) {
    try {
      setResourceLoading('loading');
      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchTopicResources(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            topicId: Number(topicId),
          }
        );
      if (response) {
        if (isContentLockFeature && teacher_id) {
          await getLessonLockedStatus(teacher_id);
        }
        setResourceLoading('completed');
        if (response?.data) {
          response.data.categoryResourceMap.forEach((elem) => {
            elem.categoryResources.sort((a, b) => a.rank - b.rank);
          });
          const data = response.data;
          dispatch(setTopicResources(data));
        }
      }

      // setLoading(false);
    } catch (err) {
      setResourceLoading('error');
      // setError(err);
      console.log(err);
    }
  }

  const teachClassSubjects = user_info?.teachClassSubjects.find(
    (classData) =>
      class_subject_info?.classname === classData.className &&
      class_subject_info?.section === classData.sectionName
  );
  const selectedSubject = teachClassSubjects
    ? teachClassSubjects.subjects.find(
      (subjectData) => subjectData.subjectId === Number(subject_id)
    )
    : undefined;

  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  useEffect(() => {
    if (subject_id && chapter_id && topic_id) {
      fetchTopicResources({
        subjectId: subject_id,
        chapterId: chapter_id,
        topicId: topic_id,
      });
    }

    if (topic_id) {
      fetchLessonsByModule(topic_id);
    }

    if (
      subject_id &&
      chapter_id &&
      topic_id &&
      !isNaN(Number(subject_id)) &&
      !isNaN(Number(chapter_id)) &&
      !isNaN(Number(subject_id))
    ) {
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        const meta = deepClone(downloadedMetaData);
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const offlineData =
            await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
              {
                chapterId: Number(chapter_id),
                topicId: Number(topic_id),
              }
            );
          meta[Number(chapter_id)] = offlineData.data;
        }
        setDownloadedMetaData(meta);
        setFetchingState(false);
      })();
    }
  }, [chapter_id]);

  const getLessonLockedStatus = async (teacherId: string) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.moduleLockInfoFetch(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleId: Number(topic_id),
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
          }
        );
      const data = response?.data;
      if (data) {
        setContentLockData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      // Ensure essential values are present before making the API call
      if (!lockedPopupData?.moduleId || !lockedPopupData?.category) {
        throw new Error('Module ID and category must be provided');
      }

      // Build the moduleInfo object dynamically, including resourceId only if it exists
      const moduleInfo = {
        moduleId: lockedPopupData?.moduleId,
        category: lockedPopupData?.category,
        lockStatus: lockedPopupData.lockedStatus,
        ...(lockedPopupData.lessonId && {
          lessonId: String(lockedPopupData.lessonId),
        }),
        ...(lockedPopupData.resourceId && {
          resourceId: String(lockedPopupData.resourceId),
        }),
      };

      console.log('Module info:', moduleInfo);

      // Perform the API call
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_id),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleInfo,
          }
        );

      // Check if the response is valid and handle accordingly
      if (response) {
        console.log('API response:', response);
        await getLessonLockedStatus(teacher_id);
        if (topic_id) {
          await fetchLessonsByModule(topic_id);
        }
        handleClosePopup(); // Close the popup upon successful submission
        setLockedPopData(null); // Clear the lockedPopupData state
        // Refresh lesson lock status
      } else {
        throw new Error('Unexpected API response');
      }
    } catch (error) {
      // Enhanced error handling
      console.error('Submission error:', error);

      // Display a user-friendly error message
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    const currentData = structuredClone(lockedPopupData);
    if (val && currentData) {
      currentData.lockedStatus = ContentLockStatusType[val];
      setLockedPopData(currentData);
    }
  };
  // useEffect(() => {
  //   if (subject_id && !isNaN(Number(subject_id))) {
  //     (async () => {
  //       const indexDbData = await findDownloadedSubjectByParams({
  //         subjectId: Number(subject_id),
  //       });
  //       if (indexDbData.length > 0) {
  //         const data = indexDbData[0];
  //         setDownloadedSubject(data);
  //       }
  //     })();
  //   }
  // }, [currentDownload]);

  console.log(contentLockData, 'contentLockData');

  const FetchLessonPlanlockstatus = async (teacherId: string, lessonPlanIds: string[]) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
          {
            personId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            lessonIds: lessonPlanIds,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          }
        );
      if (response) {
        setLockstatusData(response.lessonInfo)
      }
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.leftPanel}>
        <Box sx={styles.header}>
          <Typography variant="h1" sx={styles.twoLineClamp}>
            {selected_topic_info?.topicTitle}
          </Typography>
          <Typography variant="cardText" sx={{ color: '#007CDC' }}>
            {/* Class 8A | Science */}
            {class_subject_info
              ? 'Class ' +
              class_subject_info?.classname +
              class_subject_info?.section +
              ' | ' +
              class_subject_info?.subject
              : ''}
          </Typography>
        </Box>

        {lpLoading === 'loading' ? (
          <ReadylessonShimmer />
        ) : lessonPlans && lessonPlans.lessons.length > 0 ? (
          <ReadymadeHomeworkSection
            isError={lpLoading === 'error'}
            sectionTitle="Ready Lesson Plans"
            contentBoxSx={{ width: { md: isIpadOnly ? '100%' : pxTovW(741) } }}
            items={
              lessonPlans.lessons?.map((lesson, lessonIndex) => {
                return (
                  <LessonPlanInfoPanel
                    key={lessonIndex}
                    variant={isIpad ? 'small' : 'large'}
                    image={getMediaBasePath(
                      lesson.posterImageUrl,
                      'processedMediaBucket'
                    )}
                    mainHeading={lesson.title}
                    onClick={() => {
                      setSelectedLP(lesson);
                      setOpenPopUp(true);
                    }}
                    iconDetails={[
                      {
                        iconName: 'clock',
                        text: `${lesson.estimatedTimeInMin || 0} Min`,
                      },
                      {
                        iconName: 'questions',
                        text:
                          lesson.resourceIds.length > 1
                            ? `${lesson.resourceIds.length} resources`
                            : `${lesson.resourceIds.length} resource`,
                      },
                    ]}
                    isLocked={
                      isContentLockFeature &&
                        // contentLockData &&
                        // getLessonContentLockInfo(lesson.lessonId, contentLockData)
                        lockStatusData &&
                        getLessonPlanLockInfo(lesson.lessonId, lockStatusData)
                          ?.lockStatus ===
                        ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                        ? true
                        : false
                    }
                    status={<LessonPlanInfoPanelStatus lesson={lesson} />}
                    rootStyle={{
                      marginBottom: { md: pxTovW(20) },
                      width: {
                        xs: pxToRem(199),
                        md: pxTovW(350),
                        lg: pxTovW(295),
                      },
                      height: {
                        xs: pxToRem(241),
                        md: pxTovW(420),
                        lg: pxTovW(332),
                      },
                    }}
                  >
                    {!fetchingState && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '15px',
                          right: '10px',
                          display: 'flex',
                          gap: { xs: pxToRem(5), md: pxTovW(5) },
                        }}
                      >
                        {isContentLockFeature && (
                          <LockToggleButton
                            isLocked={
                              // contentLockData
                              //   ? getLessonContentLockInfo(
                              //     lesson.lessonId,
                              //     contentLockData
                              //   )?.lockStatus
                              //   : false
                              lockStatusData
                                ? getLessonPlanLockInfo(
                                  lesson.lessonId,
                                  lockStatusData
                                )?.lockStatus
                                : false
                            }
                            handleOpenPopup={(ev) => {
                              handleOpenPopup(ev, lesson);
                            }}
                          />
                        )}
                        <DownloadButtonWrapper
                          user={new TeacherLoginResponseType(user_info)}
                          downloadedSubject={
                            downloadedSubject
                              ? new DownloadedSubject(downloadedSubject)
                              : undefined
                          }
                          downloadedMetaData={downloadedMetaData}
                          setDownloadedMetaData={setDownloadedMetaData}
                          subjectId={Number(subject_id)}
                          classId={class_subject_info?.classId}
                          sectionId={class_subject_info?.sectionId}
                          chapterId={Number(chapter_id)}
                          topicId={Number(topic_id)}
                          lessonPlanId={lesson.lessonId}
                          title={`Lesson Plan - ${lesson?.title}`}
                          interactionEventUrl={'Teacher_lesson_plan_lists'}
                          interactionEventContext={'ready_lesson_plan'}
                        />
                      </Box>
                    )}
                  </LessonPlanInfoPanel>
                );
              })
              // []
            }
          />
        ) : (
          //   <Box sx={{ p: { xs: pxToRem(20), md: pxTovW(40) } }}>
          //   <NoContentCard
          //     variant="info"
          //     icon="cards"
          //     text="No cards to show"
          //   />
          // </Box>

          <NoContentCard
            variant="white"
            icon="coming-soon-yellow"
            text="No Lessons to show"
            rootStyle={{
              width: { xs: '100vw', md: pxTovW(741) },
              boxSizing: 'border-box',
              flexDirection: 'column',
              gap: { xs: pxToRem(20), md: pxTovW(20) },
            }}
          />
        )}
      </Box>

      <Box sx={styles.rightPanel}>
        <Box sx={styles.textWithBadge}>
          <Typography variant={'h2'}>Teaching Resources</Typography>
          <Typography variant={isIpadOnly ? 'h3' : 'h4'}>
            <ChipBadge
              label={
                topicResources?.categoryResourceMap
                  ?.map((obj) => obj.categoryResources.length)
                  .reduce((a, b) => a + b, 0) || 0
              }
              color="primary"
              size="small"
            />
          </Typography>
        </Box>

        {resourceLoading === 'loading' ? (
          <ResourceShimmer />
        ) : resourceLoading === 'error' ? (
          <Box sx={{ p: { xs: pxToRem(20), md: pxTovW(40) } }}>
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          </Box>
        ) : !topicResources ||
          topicResources.categoryResourceMap.length === 0 ? (
          <NoContentCard
            variant="soon"
            icon="hourglass-web"
            text="Coming Soon!"
            rootStyle={{
              height: { xs: pxToRem(150), md: pxTovW(212) },
              boxSizing: 'border-box',
              mt: { xs: pxToRem(20), md: pxTovW(40) },
            }}
          />
        ) : (
          <Box sx={styles.cardsContainer}>
            <>
              {topicResources.categoryResourceMap.map(
                (resource, resourceIndex) => (
                  <SectionListWithTopCarousel
                    key={resourceIndex}
                    title={resource.categoryTitle}
                    subtitle={resource.categoryDescription}
                    itemsToShow={2}
                    containerMdWidth={isIpadOnly ? pxTovW(960) : pxTovW(550)}
                    items={resource.categoryResources.map(
                      (category, categoryIndex) => (
                        <ContentDetailCard
                          locked={
                            isContentLockFeature && (
                              <LockToggleButton
                                isLocked={getCategoryLockedStatus(
                                  category.resourceId,
                                  contentLockData
                                )}
                                handleOpenPopup={(ev) => {
                                  handleOpenPopup(ev, undefined, category);
                                }}
                              />
                            )
                          }
                          onClick={async () => {
                            await interactionEvent({
                              url: 'Teacher_lesson_plan_lists',
                              context: resource.categoryTitle
                                .toLowerCase()
                                .replace(/ /g, '_'),
                              name: getResourceType(
                                resource.categoryResources[0].resourceType
                              ),
                            });
                            onResourceClick(
                              navigate,
                              {
                                resourceId: category.resourceId,
                                subjectId: Number(subject_id),
                                chapterId: Number(chapter_id),
                                topicId: Number(topic_id),
                              },
                              SessionModeEnum.SESSION_MODE_TEACH
                            );
                          }}
                          showVideoIcon={
                            category.resourceType ===
                            Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                          }
                          key={categoryIndex}
                          variant={isIpadOnly ? 'large' : 'small'}
                          image={getMediaBasePath(
                            category.posterImageUrl,
                            'processedMediaBucket'
                          )}
                          heading={category.title}
                          iconDetails={iconDetailsCDC(category)}
                          isLocked={
                            isContentLockFeature &&
                            getCategoryLockedStatus(
                              category.resourceId,
                              contentLockData
                            ) ===
                            ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                          }
                        />
                      )
                    )}
                  />
                )
              )}
            </>
          </Box>
        )}
      </Box>

      <InfoPopup
        iconName="homework2"
        popupText={[
          <Typography variant="h2">Do you want to select?</Typography>,
          <Box
            sx={{
              marginTop: { xs: pxToRem(41), lg: pxTovW(64) },
              display: 'flex',
              gap: { xs: pxToRem(20), lg: pxTovW(20) },
            }}
          >
            <PrimaryButton
              sx={{
                width: { xs: pxToRem(140), lg: pxTovW(226) },
                height: { xs: pxToRem(53), lg: pxTovW(85) },
              }}
              onClick={async () => {
                lessonPlanCardClickHandler(
                  selectedLP,
                  location.pathname,
                  SessionModeEnum.SESSION_MODE_TEACH
                );
                await interactionEvent({
                  url: 'Teacher_lesson_plan_lists',
                  context: 'ready_lesson_plan',
                  name: 'TEACH',
                });
              }}
            >
              <Typography variant="h2" color="white">
                TEACH
              </Typography>
            </PrimaryButton>
            <PrimaryButton
              sx={{
                width: { xs: pxToRem(140), lg: pxTovW(226) },
                height: { xs: pxToRem(53), lg: pxTovW(85) },
                backgroundColor: '#007CDC',
              }}
              onClick={async () => {
                lessonPlanCardClickHandler(
                  selectedLP,
                  location.pathname,
                  SessionModeEnum.SESSION_MODE_PREPARE_LESSON
                );
                await interactionEvent({
                  url: 'Teacher_lesson_plan_lists',
                  context: 'ready_lesson_plan',
                  name: 'PREPARE',
                });
              }}
            >
              <Typography variant="h2" color="white">
                PREPARE
              </Typography>
            </PrimaryButton>
          </Box>,
        ]}
        background="#007CDC"
        handleClose={() => {
          setSelectedLP(undefined);
          setOpenPopUp(false);
        }}
        open={openPopUp}
      />
      {lockedPopupData && (
        <ContentLockPopup
          open={isContentLockPopupOpen}
          onClose={handleClosePopup}
          onSubmit={(ev?: React.MouseEvent) =>
            lockedPopupData && handleSubmit(ev)
          }
          selectedValue={lockedPopupData.lockedStatus}
          onRadioChange={handleLockedRadioChange}
          lockDataObj={lockedPopupData}
        />
      )}
    </Box>
  );
}

const iconDetailsCDC = (resource: ResourceInfo) => {
  const retValue = [
    {
      iconName: 'clock',
      text: `${resource.estimatedTimeInMin} Min`,
    },

    {
      iconName: resourceTypeName(resource.resourceType).icon,
      text: resourceTypeName(resource.resourceType).name,
    },
  ];

  return retValue;
};

interface InfoDisplayPanelStatus {
  lesson: LessonInfo;
}
const LessonPlanInfoPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');
  const editTime = getHumanReadableTimestampString(lesson.modifiedOn)?.split(
    ' '
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name="geneo-blue"
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            lesson.teacherProfileImageUrl ||
            firstLetterImage(lesson.teacherName) ||
            firstLetterImage('Geneo')
          }
        />

        <Typography variant="smallText" fontWeight="bold">
          {user_info?.teacherProfileId.toString() ===
            lesson.createdBy.toString()
            ? 'You'
            : lesson.teacherName || 'Geneo'}
        </Typography>
      </Box>

      <Box>
        {lesson.lastSessionTime ? (
          <Typography variant="subText" color="text.disabled">
            {taughtTime && Number(taughtTime[0]) < 2
              ? 'Taught Today'
              : 'Taught ' + taughtTime?.join(' ')}
          </Typography>
        ) : (
          editTime &&
          lesson.teacherName &&
          !lesson.lastSessionTime && (
            <Typography variant="subText" color="text.disabled">
              {Number(editTime[0]) < 2
                ? 'Recently Edited'
                : 'Edited ' + editTime?.join(' ')}
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
};

const getCategoryLockedStatus = (
  resourceId: string,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.lockedResourceIds?.includes(
    resourceId
  )
    ? ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    : ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED;
};

const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.lessonsLockInfo.find(
    (val) => val.lessonId === lessonId
  );
};
const getLessonPlanLockInfo = (
  lessonId: string,
  contentLockData?: LessonNodeLock[]
) => {
  return contentLockData?.find(
    (val) => val.lessonId === lessonId
  );
};
