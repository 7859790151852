import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  ClassAndSubjectPopup,
  HeaderIconProps,
  HeaderIconWrapper,
  IClassAndSubjectSelected,
  IStyles,
  SecondaryButton,
  deserify,
  findOfflineUserRequestByParamsDexie,
  getLocalStorage,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ANALYTICS_CLASS_SELECTION,
  HOME,
  HOMEWORK_CHAPTER_SELECTION,
  HOMEWORK_MANAGE,
  TEACH_CHAPTER_SELECTION,
} from '../../routeHandling/RoutesNomenclature';

import CloseIcon from '@mui/icons-material/Close';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { v4 as uuidv4 } from 'uuid';
import { useConnectedClassContext } from '../../app/Context/ConnectedClassContextProvider';
import { useDownloadContext } from '../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import { subjectsWithClass } from '../../utils/icons';
import { interactionEvent } from '../Auth/auth.events';
import { setIsLoggedInOffline, setUserInfo } from '../Auth/reducer/auth.slice';
import { aiHomeworkCloseEvent } from '../Homework/homework.events';
import { resetConnectedClassState } from '../Teach/reducer/connectedClass.slice';
import { resetTeachState } from '../Teach/reducer/teach.slice';
import {
  resetCustomHomework,
  resetDraftUploadedFiles,
  resetHomeworkInstructions,
} from '../Homework/reducer/homework.slice';
import ConfigSelector from '../OfflineScreens/Teach/components/ConfigSelector';
import { setSelectedResourceSessionId } from '../Teach/reducer/teach.slice';
import {
  aiChapterCloseEvent,
  aiLessonCloseEvent,
  aiTopicCloseEvent,
} from '../Teach/teach.events';
import FeatureList, { FeatureType } from './components/FeatureList';
import HomeHomeworksList from './components/HomeHomeworksList';
import HomeLessonPlansList from './components/HomeLessonPlansList';
import { TeacherHomeMobileCarousel } from './components/TeacherHomeMobileCarousel';
import { TeacherHomeWebCarousel } from './components/TeacherHomeWebCarousel';
import { aiBookCloseEvent, aiBookOpenEvent } from './home.events';
import { setClassAndSubjectInfo } from './reducer/homeDashboard.slice';
const styles: IStyles = {
  root: {},
  mainContainer: {
    padding: {
      md: `${pxTovW(40)} ${pxTovW(40)}`,
      lg: `${pxTovW(200)} ${pxTovW(240)}`,
    },
    paddingTop: { md: pxToRem(120), lg: pxTovW(184) },
  },
};

export const Home = () => {
  const location = useLocation();
  const token = deserify(
    useAppSelector((state) => state.auth.user_info?.token)
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { user_info, is_logged_in_offline } = deserify(
    useAppSelector((state) => state.auth)
  );
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const [currentRoute, setCurrentRoute] = useState('');
  const teacher_profile_id = deserify(getLocalStorage('userId'));
  const { isOffline } = useDownloadContext();

  // if we access the page /home?offline=true we are forcing the frontend to open the homepage in offline mode
  const forcedOffline =
    new URLSearchParams(location.search).get('offline') === 'true';

  const isOfflineFrontend = isOffline || forcedOffline || is_logged_in_offline;

  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const { chapter_resources, selected_topic_info, selected_lessons_info } =
    deserify(useAppSelector((state) => state.teach));
  const { selected_hw_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  const { resetConnectedClass } = useConnectedClassContext();

  const { setSelectedFunction } = useGlobalContext();
  const backButtonClick = async () => {
    navigate(HOME);
  };

  useEffect(() => {
    if (!isOffline && is_logged_in_offline) {
      offlineLogin();
    }
  }, [isOffline]);

  const offlineLogin = async () => {
    if (!user_info) {
      return;
    }
    const offlineUser = await findOfflineUserRequestByParamsDexie({
      username: user_info?.userName,
    });
    if (!offlineUser) {
      return;
    }
    const res2 =
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.validateLoginSession(
        {
          userName: offlineUser?.username,
          verificationCode: offlineUser?.passwordHash,
          role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        }
      );
    setLocalStorage('activeSession', res2.activeSessionId.toString());
    dispatch(setIsLoggedInOffline(false));
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(resetHomeworkInstructions());
    dispatch(resetConnectedClassState());
    dispatch(resetTeachState());
    resetConnectedClass();
    dispatch(resetCustomHomework());
    dispatch(resetDraftUploadedFiles());
    fetchTeacherProfile();
    (async () => {
      await aiLessonCloseEvent(selected_lessons_info?.lessonId);
      await aiTopicCloseEvent(selected_topic_info?.topicId);
      await aiChapterCloseEvent(chapter_resources?.chapterId);
      await aiHomeworkCloseEvent(selected_hw_id);
      dispatch(setSelectedResourceSessionId(undefined));
    })();

    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  async function fetchTeacherProfile() {
    try {
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.fetchTeacherProfile(
          {
            teacherProfileId: teacher_profile_id,
          }
        );
      const resp = response.data;
      if (resp) {
        const accessKey = resp.schoolDetails[0]?.schoolId.toString()
          ? 'school-' + resp.schoolDetails[0].schoolId.toString()
          : 'geneo';
        await storeOfflineAccessKeyInCache(accessKey);
        if (token) {
          resp.token = token;
        }
        dispatch(setUserInfo(resp));
      }
    } catch (err) {
      console.log(err);
    }
  }

  // useEffect(() => {
  //   //geneo.ums.login.UMSLoginAPIServiceV1/fetchTeacherProfile
  // }, []);

  //^ SubjectPopup
  const [subjectPopupState, setSubjectPopupState] = useState(false);

  const dashBoardrouting = async (route: string) => {
    if (!class_subject_info) {
      setSubjectPopupState(true);
      setCurrentRoute(route);
    } else {
      // Redirect if offline
      if (isOfflineFrontend) {
        navigate('/offline' + route);
        return;
      }
      navigate(route);
    }
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const commonInteractionEvent = async (context: string, name: string) => {
    await interactionEvent({
      url: 'Teacher_Home',
      context: context,
      name: name,
    });
  };

  const featureList: FeatureType[] = [
    {
      fileName: 'book',
      type: 'png',
      cardText: 'Create your Homework in 2 Minutes',
      bgColor: '#EEFFB3',
      onClick: () => {
        commonInteractionEvent('Features', 'CREATE_YOUR_HOMEWORK');
        dashBoardrouting(
          `${HOMEWORK_CHAPTER_SELECTION}/${class_subject_info?.subjectId}`
        );
      },
      isDisabled: isOfflineFrontend,
    },
    {
      fileName: 'provision',
      type: 'png',
      cardText: 'Curriculum Aligned Teaching Resources',
      bgColor: '#DAF5FF',
      onClick: () => {
        commonInteractionEvent('Features', 'CURRICULUM_TEACHING_RESOURCES');
        dashBoardrouting(
          `${TEACH_CHAPTER_SELECTION}/${class_subject_info?.subjectId}`
        );
      },
    },
    {
      fileName: 'homework',
      type: 'png',
      cardText: 'Know Your Class (Pre-Assessment)',
      bgColor: '#FFEEEE',
      onClick: () => {
        commonInteractionEvent('Features', 'CLASS_ANALYSIS');
        navigate(ANALYTICS_CLASS_SELECTION);
      },
      isDisabled: isOfflineFrontend,
    },
  ];

  const headerIconList: HeaderIconProps[] = [
    {
      fileName: 'teach',
      type: 'png',
      cardText: 'Teach',
      onClick: () => {
        commonInteractionEvent('Navigation_bar', 'TEACH');
        dashBoardrouting(
          `${TEACH_CHAPTER_SELECTION}/${class_subject_info?.subjectId}`
        );
      },
    },
    {
      fileName: 'new-hw',
      type: 'png',
      cardText: 'New Homework',
      onClick: () => {
        commonInteractionEvent('Navigation_bar', 'NEW_HOMEWORK');
        dashBoardrouting(
          `${HOMEWORK_CHAPTER_SELECTION}/${class_subject_info?.subjectId}`
        );
      },
      isDisabled: isOfflineFrontend,
    },
    {
      fileName: 'manage-hw',
      type: 'png',
      cardText: 'Manage Homework',
      onClick: () => {
        commonInteractionEvent('Navigation_bar', 'MANAGE_HOMEWORK');
        navigate(HOMEWORK_MANAGE);
      },
      isDisabled: isOfflineFrontend,
    },
    {
      fileName: 'analytics',
      type: 'png',
      cardText: 'Analysis',
      onClick: () => {
        commonInteractionEvent('Navigation_bar', 'ANALYSIS');
        navigate(ANALYTICS_CLASS_SELECTION);
      },
      isDisabled: isOfflineFrontend,
    },
  ];

  const pathSegments = location.pathname.split('/');
  const classAndsubjectClickHandler = async (
    inputClassInfo: IClassAndSubjectSelected
  ) => {
    await interactionEvent({
      url: '',
      context: 'Subject_&_Class_Selection',
      name: inputClassInfo.subject.toUpperCase(),
      pathSegments: pathSegments,
    });
    if (class_subject_info) await aiBookCloseEvent(class_subject_info.bookId);
    await aiBookOpenEvent(inputClassInfo.bookId, uuidv4());

    dispatch(setClassAndSubjectInfo(inputClassInfo));
    setSubjectPopupState(false);
    if (currentRoute) {
      if (currentRoute.includes('undefined')) {
        const newRoute = currentRoute.replace(
          'undefined',
          inputClassInfo.subjectId.toString()
        );
        // Redirect if offline
        if (isOfflineFrontend) {
          navigate('/offline' + newRoute);
          return;
        }
        navigate(newRoute);
      } else {
        navigate(currentRoute);
      }
    }
    setCurrentRoute('');
  };

  function getDeviceType() {
    const userAgent = navigator.userAgent;
    console.log('userAgent', userAgent);
    if (/Android/i.test(userAgent)) {
      return 'android';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return 'ios';
    } else {
      return 'web';
    }
  }

  // Check device type
  const deviceType = getDeviceType();

  return (
    <Box>
      {mobile && (
        <Box sx={{ p: `${pxToRem(20)} ${pxToRem(20)} 0` }}>
          <SecondaryButton
            witharrow={class_subject_info?.subject ? false : true}
            onClick={() => setSubjectPopupState(true)}
            // sx={{ position: 'relative', width: '100%' }}
          >
            {class_subject_info && class_subject_info?.classname ? (
              <Typography
                textAlign={'left'}
                width={'100%'}
                variant="button"
              >{`${class_subject_info?.classname} ${class_subject_info?.section} - ${class_subject_info?.subject}`}</Typography>
            ) : (
              <Typography variant="button">Choose Class & Subject</Typography>
            )}
            {class_subject_info?.subject && (
              <CloseIcon
                sx={{
                  position: 'absolute',
                  right: '10px',
                  display: 'inline-block',
                  fontSize: { xs: pxToRem(14), md: pxTovW(18) },
                }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  dispatch(setClassAndSubjectInfo(undefined));
                }}
              />
            )}
          </SecondaryButton>
        </Box>
      )}

      <Box sx={{ position: { md: 'fixed' }, width: '100vw', zIndex: 1 }}>
        <HeaderIconWrapper icons={headerIconList} />
      </Box>

      <Box sx={styles.mainContainer}>
        {isOfflineFrontend && (
          <Box
            sx={{
              marginTop: { xs: pxToRem(10), md: pxTovW(20) },
              marginBottom: { xs: pxToRem(10), md: pxTovW(10) },
            }}
          >
            <ConfigSelector />
          </Box>
        )}

        <Box>{!isOfflineFrontend && <HomeLessonPlansList />}</Box>

        <Box>
          {!isOfflineFrontend && (
            <HomeHomeworksList
              seeAllClickHandler={async () => {
                commonInteractionEvent('homework_list', 'SEE_ALL');
                navigate(HOMEWORK_MANAGE);
                await interactionEvent({
                  url: 'Teacher_home',
                  context: 'homeworks',
                  name: 'SEE_ALL',
                });
              }}
            />
          )}
        </Box>

        <Box>
          <FeatureList
            feaureList={featureList}
            background={
              mobile
                ? 'linear-gradient(to bottom, white 25%,#FCF1C7 25% 50%,white 50% 100%)'
                : undefined
            }
          />
        </Box>

        {!isOfflineFrontend ? (
          <Box
            sx={{
              p: { xs: pxToRem(18), md: pxTovW(0) },
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: pxToRem(10) },
            }}
          >
            <Typography variant="h2" mb={pxTovW(22)}>
              Performance Snapshot
            </Typography>

            {mobile ? (
              <TeacherHomeMobileCarousel />
            ) : (
              <TeacherHomeWebCarousel />
            )}
          </Box>
        ) : null}
      </Box>

      <ClassAndSubjectPopup
        modalState={subjectPopupState}
        setModalState={setSubjectPopupState}
        displayData={subjectsWithClass}
        classSubjectsList={user_info?.teachClassSubjects}
        classAndsubjectClickHandler={classAndsubjectClickHandler}
      />
      {/* {deviceType === 'web' && <Notification />} */}
    </Box>
  );
};

export default Home;

/*

<Box sx={{ display: 'flex', gap: pxTovW(28) }}>
  {Array.from({ length: 3 }).map(() => (
    <ImageWrapper
      name="Group41484"
      type="png"
      parentFolder="tempAssets"
      styles={{
        width: pxTovW(446),
        height: pxTovW(215),
        objectFit: 'cover',
        borderRadius: pxTovW(30),
        // boxShadow: `0px 0px ${pxTovW(10)} #E7E7E7D9`,
      }}
    />
  ))}
</Box>

*/
