import {
  IStyles,
  InputFieldContainer,
  LinkButton,
  Loader,
  PrimaryButton,
  deserify,
  isValidMobileNumber,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { OTPEnum } from '@protos/user_management/ums.db_pb';
import { useGlobalContext } from 'apps/teacher/src/app/Context/GlobalContextProvider';
import { useAppSelector } from 'apps/teacher/src/reduxStore/reduxHooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  HOME,
  OTP_SCREEN,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../../../../routeHandling/RoutesNomenclature';
import {
  setOtpInfo,
  setToastInfo,
  setUserInfo,
  setVerifyPath,
} from '../../reducer/auth.slice';
const styles: IStyles = {
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: { xs: 'start', md: 'center' },
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(5),
    textAlign: { xs: 'left', md: 'center' },
    // flexGrow: 1,
    // margin: 'auto',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(61) },
  },
  inputContainer: {
    mt: { xs: pxToRem(50), md: pxTovW(60) },
  },
  skip: {
    width: { xs: pxToRem(75), md: pxTovW(140) },
    height: { xs: pxToRem(42), md: pxTovW(82) },
    // position: 'relative',
    // right: '100%',
    '& > span': {
      fontSize: pxToRem(16),
      color: '#007CDC',
      // backgroundColor: 'blue',
      padding: '0px',
    },
  },

  secondaryButtonBox: {
    width: { xs: '100%', md: pxTovW(517) },
    margin: 'auto',
    mt: { xs: pxToRem(92), md: pxTovW(38) },
  },
};
export const VerifyPhone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentialError, setCredentialError] = useState<string | undefined>();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { user_validation_info, user_info } = deserify(
    useAppSelector((state) => state.auth)
  );
  const [phoneNumber, setPhoneNumber] = useState(
    user_info?.phoneNumber || user_validation_info?.phoneNumber || ''
  );
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  // For backclick redirection

  const { selectedFunction, setSelectedFunction } = useGlobalContext();

  const backClick = () => {
    // dispatch(
    //   setToastInfo({
    //     label: 'user Id already activated',
    //     variant: 'success',
    //     open: true,
    //   })
    // );
    navigate(HOME);
  };
  useEffect(() => {
    setSelectedFunction(() => backClick);
  }, []);
  const handleNext = () => {
    dispatch(setVerifyPath(VERIFY_PHONE));
    navigate(OTP_SCREEN);
  };
  const handleCredentialChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isValidMobileNumber(event.target.value)) {
      setCredentialError('Enter valid Phone Number');
    } else {
      setCredentialError(undefined);
    }
    setPhoneNumber(event.target.value);
  };
  const nextClickHandler = async () => {
    if (!isValidMobileNumber(phoneNumber)) {
      setCredentialError('enter valid phone number');
      return;
    }
    try {
      setLoading('loading');
      const [updateProfile, sendOtp] = await Promise.all([
        UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile({
          teacherProfileId: user_validation_info?.profileId,
          phoneNumber: phoneNumber,
          phoneVerify: 0,
        }),
        UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
          phoneNumber: phoneNumber,
          otpType: OTPEnum.TEACHER_VERIFICATION,
        }),
      ]);

      if (updateProfile.status === 200 && sendOtp.status === 200) {
        setLoading('completed');
        // dispatch(
        //   setToastInfo({
        //     label: 'otp sent to your phone',
        //     variant: 'success',
        //     open: true,
        //   })
        // );
        dispatch(
          setOtpInfo({
            verification_code: sendOtp.verificationCode,
            otp_type: OTPEnum.TEACHER_VERIFICATION,
          })
        );
        dispatch(setUserInfo(updateProfile.data));
        dispatch(setVerifyPath(VERIFY_PHONE));
        navigate(OTP_SCREEN);
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'invalid Phone Number',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      console.log(error);
    }
  };

  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          ...styles.root,
          width: { xs: '100%', md: '50%' },
          display: 'flex',
        }}
      >
        <Box sx={styles.mainContainer}>
          <Box
            sx={{
              display: 'flex',
              // backgroundColor: 'red',
              alignItems: 'flex-end',
              width: '100%',
              justifyContent: { xs: 'space-between', md: 'center' },
              textAlign: 'center',
              // gap: { md: pxTovW(239) },
            }}
          >
            <Box sx={styles.heading}>
              <Typography variant="h1" fontWeight="bold">
                Connect Your
              </Typography>
              <Typography variant="h1" color="primary" fontWeight="bold">
                Phone Number
              </Typography>
            </Box>


            <Box
              sx={{
                position: { md: 'fixed' },
                right: { md: pxTovW(100) },
                // backgroundColor: 'blue',
              }}
            >
              <LinkButton
                sx={{ ...styles.skip }}
                onClick={() => navigate(VERIFY_EMAIL)}
              >
                Skip
              </LinkButton>
            </Box>

          </Box>
          <Box>
            <InputFieldContainer
              helperTextvariant="error"
              topLabel="Enter 10 Digit Phone Number"
              value={phoneNumber}
              onChange={handleCredentialChange}
              helperText={credentialError}
              // nonEditable={true}
              type="phoneNumber"
              onEnter={nextClickHandler}
            />
          </Box>

          <Box sx={styles.secondaryButtonBox}>
            <PrimaryButton
              fullWidth
              onClick={nextClickHandler}
              disabled={credentialError !== undefined}
            >
              <Typography variant="h1" color="success.light">
                Next
              </Typography>
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
