import {
  DragAndDropFiles,
  IStyles,
  InstructionsPanel,
  NumericalInputField,
  PrimaryButton,
  deserify,
  getFileExtensionType,
  getFileSizeInMB,
  getFileType,
  getImageType,
  getLocalStorage,
  pxToRem,
  pxTovW,
  uploadFile,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Radio, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FileEnum } from '@protos/content_management/content.db_pb';
import {
  FileUploadPurpose,
  SignedUrlReponse,
} from '@protos/learning_management/lms.common.apis_pb';
import {
  CloudUpload,
  SubmissionType,
  TaskCreationStatusEnum,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  TeacherHWCreationSubmitRequestV2,
  TeacherHWCreationSubmitResponse,
} from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ChangeEvent, DragEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { HOMEWORK_ASSIGN } from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../Auth/auth.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import {
  CustomUploadFileResponse,
  resetDraftUploadedFiles,
  resetSelectedTasksInfo,
  setCustomHomework,
  setDraftUploadedFiles,
} from '../reducer/homework.slice';

const styles: IStyles = {
  root: {
    // backgroundColor: 'blue',

    padding: { xs: `${pxToRem(0)} ${pxToRem(20)}`, md: 'unset' },
    paddingBottom: { md: pxTovW(20) },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      xs: `${pxToRem(20)} ${pxToRem(0)}`,
      md: pxToRem(20),
      lg: `${pxTovW(25)} ${pxTovW(240)} ${pxTovW(32)}`,
    },
  },
  contentBox: {
    display: 'flex',
    justifyContent: { xs: 'left', md: 'space-between' },
    flexDirection: { xs: 'column', md: 'row' },
    // alignItems: { xs: 'center', md: 'unset' },
    gap: { xs: pxToRem(20), md: pxTovW(10) },
  },
  dragAndDrop: {
    flex: '1',
    flexBasis: '50%',
    boxSizing: 'border-box',
    pl: { md: pxToRem(0), lg: pxTovW(290) },
    display: 'flex',
    flexDirection: 'column',
    alignItems: { md: 'center', lg: 'unset' },
    gap: { md: pxToRem(30), lg: 'unset' },
    '&>div': {
      // width: 'max-content',
    },
  },
  marksBox: {
    display: 'flex',
    // backgroundColor: 'red',

    // width: '100%',
    alignItems: 'center',
    gap: { xs: pxToRem(20), md: pxTovW(15) },
    mt: { xs: pxToRem(30), md: pxTovW(30) },
    mb: { xs: pxToRem(30), md: pxTovW(30) },
    p: { xs: `${pxToRem(0)}  ${pxToRem(0)}`, md: '0' },
  },
  marksInput: {
    width: { md: pxTovW(130) },
    height: { md: pxTovW(46) },

    '& input': {
      width: { md: pxTovW(130) },
      height: { md: pxTovW(46) },
      // padding: 0,
      textAlign: 'center',
      color: 'secondary.main',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield' /* Firefox */,
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
    '& .MuiOutlinedInput-root': {
      fontSize: { xs: pxToRem(30), md: pxTovW(30) },
      fontWeight: '700',
      color: 'secondary.main',
      padding: '0',
      border: `${pxTovW(1)} solid red`,
      // borderColor: isFocused ? 'neutral.cornflowerBlue' : '#CCE6FE',
      borderColor: 'neutral.cornflowerBlue',
      borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
    },
  },
  radioBox: {
    display: 'flex',
    flexDirection: { xs: 'column', lg: 'row' },
    gap: { xs: pxToRem(20), md: pxTovW(50) },
    mt: { xs: pxToRem(14), md: pxTovW(14) },
  },
  radioDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(7), md: pxTovW(7) },
  },
  radio: {
    color: '#CCE6FE',
    '& .MuiSvgIcon-root': {
      fontSize: { xs: pxToRem(30), lg: pxTovW(30) },
    },
    '&.MuiRadio-root': {
      padding: '0',
    },
  },

  instructionBox: {
    width: { xs: '100%', md: '55%', lg: '45%' },
    boxSizing: 'border-box',
    pr: { md: pxToRem(20), lg: pxTovW(240) },
    marginBottom: { xs: pxToRem(80) },
  },
  conditionalInfo: {
    backgroundColor: '#FFDFDF',
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    marginLeft: { xs: 'unset', md: 'auto' },
    mb: { xs: pxToRem(0), md: pxTovW(30) },
    p: { md: `${pxTovW(8)} ${pxTovW(12)}` },
    ml: { xs: '20%', md: pxTovW(0) },
  },
};

export const CreateCustomHw = () => {
  const {
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const [instructionsValue, setInstructionsValue] = useState<
    string | undefined
  >(undefined);

  const { setSelectedFunction } = useGlobalContext();
  const { module_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const teacher_id = getLocalStorage('userId');
  // const [marks, setMarks] = useState<number>(0);
  const [marks, setMarks] = useState<number | undefined>();
  const [radioValue, setRadioValue] = useState<string>('upload_on_app');
  const [submissionValue, setSubmissionValue] = useState<number>(
    SubmissionType.SUBMISSION_ONLINE
  );
  const [userUploads, setUserUploads] = useState<CustomUploadFileResponse[]>(
    []
  );

  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  const { chapterwise_topic, selected_tasks_info, draft_uploaded_files } =
    deserify(useAppSelector((state) => state.homework));

  const customHomework = deserify(
    useAppSelector((state) => state.homework.custom_homework)
  );

  const handleback = () => {
    dispatch(resetSelectedTasksInfo());
    navigate(-1);
  };
  useEffect(() => {
    setSelectedFunction(() => handleback);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    // console.log(draft_uploaded_files, 'userUploadedFiles');
    if (customHomework) {
      customHomework.homeworkInstructions &&
        customHomework.homeworkInstructions.map((inst) => {
          setInstructionsValue(inst);
        });

      if (customHomework.submissionType === SubmissionType.SUBMISSION_ONLINE) {
        setRadioValue('upload_on_app');
        setSubmissionValue(SubmissionType.SUBMISSION_ONLINE);
      } else {
        setRadioValue('physical_submission');
        setSubmissionValue(SubmissionType.SUBMISSION_OFFLINE);
      }
      customHomework.maxMarks && setMarks(customHomework.maxMarks);
      customHomework.taskUploads &&
        setUserUploads(
          customHomework.taskUploads.map((upload) => ({
            uploadFileData: upload,
            fileUploadStatus: 'success',
            fileSize: upload.fileSizeInMb || 0,
          }))
        );
    }

    console.log('draft_uploaded_files', draft_uploaded_files);

    if (draft_uploaded_files && draft_uploaded_files?.length > 0) {
      const tempDraftUploadedFiles =
        draft_uploaded_files?.map((upload) => ({
          uploadFileData: upload.uploadFileData,
          fileUploadStatus: upload.fileUploadStatus,
          fileSize: upload.fileSize,
        })) ?? [];
      setUserUploads(tempDraftUploadedFiles);
    }
  }, []);

  const handleDeleteFunction = async (indexToDelete: number) => {
    const tempUserUploads = [...userUploads];
    tempUserUploads[indexToDelete].uploadFileData.isDeleted = true;
    setUserUploads(tempUserUploads);
  };

  const creatingDraftHw = async (): Promise<HomeworkTask | undefined> => {
    if (
      !class_subject_info?.subjectId ||
      !class_subject_info?.sectionId ||
      !chapterwise_topic?.chapterInfo?.chapterId
    ) {
      return;
    }
    try {
      const draftHomeworkCreationParams = new TeacherHWCreationSubmitRequestV2({
        teacherId: teacher_id,
        subjectId: class_subject_info?.subjectId,
        sectionId: class_subject_info?.sectionId,
        taskName: 'Draft Custom Homework',
        chapterId: chapterwise_topic?.chapterInfo?.chapterId,
        topicId: Number(module_id),
        creationStatus: TaskCreationStatusEnum.TASK_CREATION_STATUS_DRAFT,
        taskType: TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM,
      });
      const response: TeacherHWCreationSubmitResponse =
        await LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler.homeworkCreationSubmit(
          draftHomeworkCreationParams
        );
      if (response.data?.homework) {
        dispatch(setCustomHomework(response.data.homework));
        return response.data.homework;
      } else {
        return undefined;
      }
    } catch (err) {
      console.error('creatingDraftHw error:', err);
    }
  };
  const handleProceedClick = async (status: TaskCreationStatusEnum) => {
    if (
      !class_subject_info?.subjectId ||
      !class_subject_info?.sectionId ||
      !chapterwise_topic?.chapterInfo?.chapterId
    ) {
      return;
    }
    const uploadFileDataArray =
      userUploads
        ?.filter((upload) => upload.fileUploadStatus === 'success')
        .map((upload) => {
          if (upload.uploadFileData.signedUrl) {
            upload.uploadFileData.gcpFileUrl = upload.uploadFileData.signedUrl;
          }
          return upload.uploadFileData;
        }) || [];
    const homeworkCreationParams = new TeacherHWCreationSubmitRequestV2({
      teacherId: teacher_id,
      subjectId: class_subject_info?.subjectId,
      sectionId: class_subject_info?.sectionId,
      taskName: 'New Custom Homework',
      chapterId: chapterwise_topic?.chapterInfo?.chapterId,
      topicId: Number(module_id),
      selectedTasksInfo: selected_tasks_info,
      instructions:
        instructionsValue === undefined
          ? [
              '<ul><li>Answer all questions to complete the Homework assignment.</li><li>Feel free to retry the Homework for better understanding.</li><li>Ensure Homework completion by the specified Deadline.</li></ul>',
            ]
          : [instructionsValue],
      creationStatus: status,
      taskType: TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM,
      submissionType: submissionValue as SubmissionType,
      taskUploads: uploadFileDataArray,
      maxMarks: 0,
    });
    if (marks) {
      const regex = /^\d+$/;
      if (regex.test(marks.toString())) {
        homeworkCreationParams.maxMarks = marks;
      } else {
        dispatch(
          setToastInfo({
            label: 'Marks can be whole numbers only',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
    }
    if (customHomework?.homeworkId) {
      //for draft hw when coming back from assign hw screen
      homeworkCreationParams.homeworkId = customHomework.homeworkId;
    }

    try {
      console.log(homeworkCreationParams);
      const response: TeacherHWCreationSubmitResponse =
        await LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler.homeworkCreationSubmit(
          homeworkCreationParams
        );
      if (response.data) {
        // Handle successful response
        dispatch(setCustomHomework(response.data.homework));
        dispatch(resetDraftUploadedFiles());
        navigate(`${HOMEWORK_ASSIGN}`);
        await interactionEvent({
          url: '',
          context: 'create_custom_homework',
          name: 'PROCEED',
          pathSegments: pathSegments,
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleDropFunction = async (event: DragEvent<HTMLDivElement>) => {
    await handleUploadDoc(event.dataTransfer.files);
    await interactionEvent({
      url: '',
      context: 'Homework',
      name: 'DRAG_AND_DROP',
      pathSegments: pathSegments,
    });
  };

  const handleChangeFunction = async (event: ChangeEvent<HTMLInputElement>) => {
    handleUploadDoc(event.target.files as FileList);
    await interactionEvent({
      url: '',
      context: 'create_custom_homework',
      name: 'UPLOAD',
      pathSegments: pathSegments,
    });
  };

  const pendingStateUpdate = async (filesToUpload: FileList) => {
    if (!filesToUpload) return;

    const tempUserUploadsArray: CustomUploadFileResponse[] = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      const tempUserUploads: CustomUploadFileResponse = {
        uploadFileData: new CloudUpload({
          gcpFileUrl: '',
          personId: teacher_id,
          fileSizeInMb: getFileSizeInMB(file.size),
          externalWebResourceUrl: '',
          isDeleted: false,
          fileName: file.name,
          fileType: getFileType(file.type),
          fileExtensionType: getFileExtensionType(file.type),
          isUploaded: false,
        }),
        fileUploadStatus: 'pending',
        fileSize: file.size,
      };

      tempUserUploadsArray.push(tempUserUploads);
    }

    setUserUploads([...(userUploads || []), ...tempUserUploadsArray]);
    // dispatch(setUserUploadedFiles([...(userUploads || []), ...tempUserUploadsArray]));
  };

  const handleUploadDoc = async (filesToUpload: FileList) => {
    try {
      if (!filesToUpload) return;
      const tempUserUploadsArray: CustomUploadFileResponse[] = [];
      await pendingStateUpdate(filesToUpload);
      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        const tempUserUploads: CustomUploadFileResponse = {
          uploadFileData: new CloudUpload({
            gcpFileUrl: '',
            personId: teacher_id,
            fileSizeInMb: getFileSizeInMB(file.size),
            isDeleted: false,
            externalWebResourceUrl: '',
            fileName: file.name,
            fileType: getFileType(file.type),
            fileExtensionType: getFileExtensionType(file.type),
            isUploaded: false,
          }),
          fileUploadStatus: 'pending',
          fileSize: file.size,
        };

        if (getFileType(file.type) === FileEnum.FILE_TYPE_UNKNOWN) {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Invalid Type';
          tempUserUploadsArray.push(tempUserUploads);
          continue;
        }
        //! max file size to be 50 MB
        if (getFileSizeInMB(file.size) > 50) {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Size Exceeded';
          tempUserUploadsArray.push(tempUserUploads);
          continue;
        }

        try {
          let homework: HomeworkTask;
          if (!customHomework?.homeworkId) {
            //creating a draft for new HW
            const draftHw = await creatingDraftHw();
            if (draftHw) {
              homework = structuredClone(draftHw);
            } else {
              console.error('no homework details found');
              //we are not able upload and handle error in catch block.
              tempUserUploads.uploadFileData.isUploaded = false;
              tempUserUploads.fileUploadStatus = 'Upload Error';
              tempUserUploadsArray.push(tempUserUploads);
              setUserUploads([...(userUploads || []), ...tempUserUploadsArray]);
              dispatch(
                setDraftUploadedFiles([
                  ...(userUploads || []),
                  ...tempUserUploadsArray,
                ])
              );
              return;
            }
          } else {
            homework = customHomework;
          }
          const signedUrlResponse: SignedUrlReponse =
            await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getSignedUrlForFileUpload(
              {
                personId: teacher_id,
                filesInfo: [
                  {
                    originalFilePath: file.name,
                    mimeType: getImageType(file.type),
                    size: getFileSizeInMB(file.size),
                  },
                ],
                personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
                fileUploadPurpose: FileUploadPurpose.FILE_UPLOAD_FOR_HW_ASSET,
                sectionId: class_subject_info?.sectionId,
                subjectId: class_subject_info?.subjectId,
                homeworkId: homework.homeworkId,
              }
            );

          if (signedUrlResponse && signedUrlResponse.response.length > 0) {
            const signedUrl = signedUrlResponse.response[0].signedUrl;

            // Perform your file upload here
            const uploadSuccess = await uploadFile({ file, signedUrl });
            if (uploadSuccess.success === true) {
              // If upload is successful, update status to 'success'
              tempUserUploads.uploadFileData.gcpFileUrl =
                signedUrlResponse.response[0].signedUrl;
              tempUserUploads.uploadFileData.isUploaded = true;
              tempUserUploads.fileUploadStatus = 'success';
            } else {
              tempUserUploads.uploadFileData.isUploaded = false;
              tempUserUploads.fileUploadStatus = 'Upload Error';
            }
          } else {
            tempUserUploads.uploadFileData.isUploaded = false;
            tempUserUploads.fileUploadStatus = 'Upload Error';
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Upload Error';
        }

        tempUserUploadsArray.push(tempUserUploads);
      }

      // After all uploads are done, update state and status
      setUserUploads([...(userUploads || []), ...tempUserUploadsArray]);
      dispatch(
        setDraftUploadedFiles([...(userUploads || []), ...tempUserUploadsArray])
      );
    } catch (error) {
      console.error('An error occurred during file upload:', error);
    }
  };

  const controlRadioProps = (item: string) => ({
    name: 'color-radio-button',
    value: item,
    checked: radioValue === item,
    onChange: async (event: ChangeEvent<HTMLInputElement>) => {
      setRadioValue(event.target.value);
      if (event.target.value === 'upload_on_app') {
        setSubmissionValue(SubmissionType.SUBMISSION_ONLINE);
      } else {
        setSubmissionValue(SubmissionType.SUBMISSION_OFFLINE);
      }
      await interactionEvent({
        url: '',
        context: 'create_custom_homework',
        name: event.target.value.toUpperCase(),
        pathSegments: pathSegments,
      });
    },
    inputProps: { 'aria-label': item },
  });

  return (
    <Box sx={styles.root}>
      {true && isMobile && (
        <Box sx={styles.conditionalInfo}>
          {/* <IconWrapper
            name="info_red"
            size="md"
            parentFolder="icons"
            type="png"

          /> */}
          {/* <Typography variant="h4" color="#FF0000">
            Please wait... your files are uploading
          </Typography> */}
        </Box>
      )}

      <Box sx={styles.header}>
        <Typography variant="h1" fontWeight={'medium'}>
          Create Custom Homework
        </Typography>
        <Box
          sx={{
            position: { xs: 'fixed', md: 'sticky' },
            // top: { md: pxTovW(126) },
            left: { xs: 'unset', md: '100%' }, // md: pxTovW(1079)
            right: { xs: pxToRem(0), md: '100%' },
            bottom: { xs: pxToRem(10), md: '100%' },
            width: { xs: '100%', md: pxTovW(397) },
            display: 'flex',
            justifyContent: 'center',
            zIndex: { xs: '100', md: '0' },
          }}
        >
          <PrimaryButton
            sx={{ width: { xs: '90%', md: '100%' } }}
            disabled={userUploads?.some(
              (item) => item.fileUploadStatus === 'pending'
            )}
            onClick={() =>
              handleProceedClick(
                TaskCreationStatusEnum.TASK_CREATION_STATUS_APPROVED
              )
            }
          >
            Proceed
          </PrimaryButton>
        </Box>
      </Box>

      <Box sx={styles.contentBox}>
        <Box sx={styles.dragAndDrop}>
          {/* <DragAndDropFiles /> */}
          <Box>
            <DragAndDropFiles
              handleDropFunction={handleDropFunction}
              handleChangeFunction={handleChangeFunction}
              handleDeleteFunction={handleDeleteFunction}
              userUploads={userUploads || []}
              // uploadDoc={handleUploadDoc}
            />
          </Box>
          <Box sx={styles.marksBox}>
            <Typography
              variant={isMobile ? 'h2' : 'h3'}
              fontWeight={isMobile ? 'medium' : 'regular'}
            >
              Marks
            </Typography>
            <NumericalInputField
              variant="outlined"
              value={marks}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (Number(value) > 1000) return;
                value ? setMarks(Number(value)) : setMarks(undefined);
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h3"
              fontWeight={isMobile ? 'medium' : 'regular'}
            >
              Submission
            </Typography>

            <Box sx={styles.radioBox}>
              <Box sx={styles.radioDetails}>
                <Radio
                  sx={styles.radio}
                  {...controlRadioProps('physical_submission')}
                />
                <Typography variant="h2" fontWeight="regular">
                  Physical Submission
                </Typography>
              </Box>

              <Box sx={styles.radioDetails}>
                <Radio
                  sx={styles.radio}
                  {...controlRadioProps('upload_on_app')}
                />
                <Typography variant="h2" fontWeight="regular">
                  Upload on App
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.instructionBox}>
          {/* // todo: it should show when PROCEED is clicked & not all files are uploaded, hide when all files uploaded  */}
          {true && !isMobile && (
            <Box>
              {/* <IconWrapper
                name="info_red"
                size="md"
                parentFolder="icons"
                type="png"
                // customSx={styles.deleteFileHandler}
              /> */}
              {/* sx={styles.conditionalInfo} */}
              {/* <Typography variant="h4" color="#FF0000">
                Please wait... your files are uploading
              </Typography> */}
            </Box>
          )}

          <Box>
            <InstructionsPanel
              isEditable
              value={instructionsValue}
              onChange={(e: string | undefined) => {
                console.log(e);
                setInstructionsValue(e);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
