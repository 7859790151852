import { Timestamp } from '@bufbuild/protobuf';
import {
  DownloadPopup,
  IStyles,
  calculateDownloadProgress,
  categorizeLockInfo,
  deleteDownloadedChapter,
  deleteDownloadedLessonPlan,
  deleteDownloadedTopic,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  ChapterMetaInfo,
  DownloadedSubject,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { useDownloadContext } from '../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../app/Context/GlobalContextProvider';
import { setToastInfo } from '../../pages/Auth/reducer/auth.slice';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { interactionEvent } from '../../pages/Auth/auth.events';

const styles: IStyles = {};

interface IProps {
  downloadedSubject?: DownloadedSubject;
  downloadedMetaData: Record<number, ChapterMetaInfo | undefined>;
  setDownloadedMetaData: (
    _val: Record<number, ChapterMetaInfo | undefined>
  ) => void;
  subjectId: number;
  classId?: number;
  sectionId?: number;
  chapterId?: number;
  topicId?: number;
  lessonPlanId?: string;
  chapterIds?: number[];
  user: TeacherLoginResponseType;
  title?: string;
  interactionEventUrl: string;
  interactionEventContext: string;
  lockedData?: ContentLockModuleData;
}

const checkSyncAvailability = (
  progress: number,
  lastUpdatedAt: Timestamp | undefined,
  updateTimestamp: Timestamp | undefined
): boolean => {
  if (!lastUpdatedAt || !updateTimestamp) return false;
  const lastUpdate = new Timestamp(lastUpdatedAt).toDate().getTime();
  const backendUpdate = new Timestamp(updateTimestamp).toDate().getTime();
  return progress === 100 && backendUpdate > lastUpdate;
};

export const DownloadButtonWrapper = ({
  user,
  downloadedSubject,
  downloadedMetaData,
  setDownloadedMetaData,
  subjectId,
  classId,
  sectionId,
  chapterId,
  topicId,
  lessonPlanId,
  chapterIds,
  title,
  interactionEventUrl,
  interactionEventContext,
  lockedData,
}: IProps) => {
  let progress = 0;
  let isSyncAvail = false;
  const { addDownload, isDownloading } = useDownloadContext();
  const { setShowAppLoader } = useGlobalContext();
  const dispatch = useAppDispatch();
  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const lockInfo = categorizeLockInfo(lockedData);

  if (lessonPlanId) {
    if (
      lockInfo.lesson[lessonPlanId]?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      return null;
    }
    const downloadedChapters = downloadedSubject?.chapters || {};
    const downloadedTopics = chapterId
      ? (downloadedChapters[chapterId] &&
          downloadedChapters[chapterId].downloadedTopics) ||
        {}
      : {};
    const downloadedTopic = topicId ? downloadedTopics[topicId] : undefined;
    const lessonData = downloadedTopic?.downloadedLessonPlans[lessonPlanId];
    const downloadedTopicMetadata =
      chapterId && downloadedMetaData[chapterId]
        ? downloadedMetaData[chapterId]?.topics.find((val) => val.id == topicId)
        : undefined;
    const downloadedLessonPlanMetadata = downloadedTopicMetadata
      ? downloadedTopicMetadata.lessonPlans.find(
          (val) => val.id == lessonPlanId
        )
      : undefined;

    progress = calculateDownloadProgress(
      user,
      downloadedSubject,
      chapterId ? downloadedMetaData[chapterId] : undefined,
      chapterId,
      topicId,
      lessonPlanId,
      sectionId,
      lockedData
    );

    isSyncAvail = checkSyncAvailability(
      progress,
      lessonData?.lastUpdatedAt,
      downloadedLessonPlanMetadata?.updateTimestamp
    );
  } else if (topicId) {
    if (
      lockInfo.topic[topicId]?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      return null;
    }
    const downloadedChapters = downloadedSubject?.chapters || {};
    const downloadedTopics = chapterId
      ? (downloadedChapters[chapterId] &&
          downloadedChapters[chapterId].downloadedTopics) ||
        {}
      : {};
    const topicData = downloadedTopics[topicId];

    progress = calculateDownloadProgress(
      user,
      downloadedSubject,
      chapterId ? downloadedMetaData[chapterId] : undefined,
      chapterId,
      topicId,
      undefined,
      sectionId,
      lockedData
    );

    const downloadedTopicMetadata =
      chapterId && downloadedMetaData[chapterId]
        ? downloadedMetaData[chapterId]?.topics.find(
            (val) => val.id === topicId
          )
        : undefined;

    isSyncAvail = checkSyncAvailability(
      progress,
      topicData?.lastUpdatedAt,
      downloadedTopicMetadata?.updateTimestamp
    );
  } else if (chapterId) {
    if (
      lockInfo.chapter[chapterId]?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      return null;
    }
    const chapData = downloadedSubject?.chapters
      ? downloadedSubject.chapters[chapterId]
      : undefined;

    progress = calculateDownloadProgress(
      user,
      downloadedSubject,
      downloadedMetaData[chapterId],
      chapterId,
      undefined,
      undefined,
      sectionId,
      lockedData
    );

    isSyncAvail = checkSyncAvailability(
      progress,
      chapData?.lastUpdatedAt,
      downloadedMetaData[chapterId]?.updateTimestamp
    );
  } else if (subjectId && chapterIds) {
    if (
      lockInfo.book[subjectId]?.lockStatus ===
      ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
    ) {
      return null;
    }
    const totalProgress = chapterIds.reduce((acc, chapId) => {
      const chapProgress = calculateDownloadProgress(
        user,
        downloadedSubject,
        downloadedMetaData[chapId],
        chapId,
        undefined,
        undefined,
        sectionId,
        lockedData
      );
      return acc + (chapProgress || 0);
    }, 0);
    progress = totalProgress / chapterIds.length;

    isSyncAvail = chapterIds.some((chapId) => {
      const chapData = downloadedSubject?.chapters
        ? downloadedSubject.chapters[chapId]
        : undefined;
      return checkSyncAvailability(
        progress,
        chapData?.lastUpdatedAt,
        downloadedMetaData[chapId]?.updateTimestamp
      );
    });
  }

  return (
    <DownloadPopup
      isDownloading={
        !!(classId && sectionId) &&
        isDownloading({
          subjectId: subjectId,
          classId: classId,
          sectionId: sectionId,
          chapterId: chapterId,
          topicId: topicId,
          lessonPlanId: lessonPlanId,
          downloadedMetaData,
        })
      }
      progress={progress}
      isSyncAvail={isSyncAvail}
      onDownloadClick={async () => {
        if (!user || !sectionId || isNaN(subjectId)) {
          return;
        }
        dispatch(
          setToastInfo({
            label: `Downloading ${title}`,
            variant: 'success',
            open: true,
          })
        );
        // Fetch Missing meta data
        let chapterIdsToFetch: number[] = [];
        if (lessonPlanId && topicId && chapterId) {
          if (
            !downloadedMetaData[chapterId]?.topics
              .find((top) => top.id === topicId)
              ?.lessonPlans.find((lp) => lp.id === lessonPlanId)
          ) {
            chapterIdsToFetch = [chapterId];
          }
        } else if (topicId && chapterId) {
          if (
            !downloadedMetaData[chapterId]?.topics.find(
              (top) => top.id === topicId
            )
          ) {
            chapterIdsToFetch = [chapterId];
          }
        } else if (chapterId) {
          if (!downloadedMetaData[chapterId]) {
            chapterIdsToFetch = [chapterId];
          }
        } else {
          if (chapterIds) {
            for (let i = 0; i < chapterIds.length; i++) {
              if (!downloadedMetaData[chapterIds[i]]) {
                chapterIdsToFetch.push(chapterIds[i]);
              }
            }
          }
        }
        if (chapterIdsToFetch.length) {
          for (let i = 0; i < chapterIdsToFetch.length; i++) {
            const chapId = chapterIdsToFetch[i];
            const offlineData =
              await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
                {
                  chapterId: chapId,
                }
              );
            if (offlineData.data) {
              downloadedMetaData[chapId] = offlineData.data;
            }
          }
          setDownloadedMetaData(downloadedMetaData);
        }
        if (classId && sectionId) {
          addDownload({
            subjectId: subjectId,
            classId: classId,
            sectionId: sectionId,
            chapterId: chapterId,
            topicId: topicId,
            lessonPlanId: lessonPlanId,
            downloadedMetaData,
            lockedData,
          });
        }
        await interactionEvent({
          url: interactionEventUrl,
          context: interactionEventContext,
          name: isSyncAvail ? 'SYNC' : 'DOWNLOAD',
        });
      }}
      onDeleteClick={async () => {
        console.log('On Delete Click');
        dispatch(
          setToastInfo({
            label: `Deleting ${title}`,
            variant: 'success',
            open: true,
          })
        );
        setShowAppLoader(true);
        if (lessonPlanId && topicId && chapterId) {
          await deleteDownloadedLessonPlan({
            subjectId: subjectId,
            chapterId: chapterId,
            topicId: topicId,
            lessonId: lessonPlanId,
            sectionId: sectionId,
            userId: user?.teacherProfileId.toString(),
          });
        } else if (topicId && chapterId) {
          await deleteDownloadedTopic({
            subjectId: subjectId,
            chapterId: chapterId,
            topicId: topicId,
            sectionId: sectionId,
            userId: user?.teacherProfileId.toString(),
          });
        } else if (chapterId) {
          await deleteDownloadedChapter({
            subjectId: subjectId,
            chapterId: chapterId,
            sectionId: sectionId,
            userId: user?.teacherProfileId.toString(),
          });
        } else if (chapterIds) {
          for (let i = 0; i < chapterIds?.length; i++) {
            await deleteDownloadedChapter({
              subjectId: subjectId,
              chapterId: chapterIds[i],
              sectionId: sectionId,
              userId: user?.teacherProfileId.toString(),
            });
          }
        }
        setShowAppLoader(false);
        dispatch(
          setToastInfo({
            label: `${title} deleted successfully`,
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: interactionEventUrl,
          context: interactionEventContext,
          name: 'DELETE',
        });
      }}
    />
  );
};
